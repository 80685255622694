<template>
  <drawer-layout
    :title="setTitle"
    :show="open"
    :isChapter="false"
    :isForm="isForm"
    :btnLoading="btnLoading"
    :permission="parent.isTongChou ? 'production:stage:button:edit' : 'production:stage:button:edit:isTongChou'"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
  >
    <!-- {{ { curRow } }} -->
    <div slot>
      <g-title>基本信息</g-title>
      <info-item :label-width="labelWidth" label="名称">{{ curRow.stageName }}</info-item>
      <info-item :label-width="labelWidth" label="序号">{{ curRow.sequenceNo }}</info-item>
      <info-item :label-width="labelWidth" label="人员"
        ><div>
          <img
            v-if="curRow.user"
            style="width:30px;height:30px;border-radius:50%;margin-right:10px;"
            :src="curRow.user.avatar"
            alt=""
          />
          {{ curRow ? curRow.userName : '' }}
        </div></info-item
      >
      <info-row :label-width="labelWidth" label="状态">{{ curRow.status ? '开' : '关' }}</info-row>
      <info-row :label-width="labelWidth" label="翻译系统">{{ curRow.applyTranslation == 1 ? '是' : '否' }}</info-row>
      <info-row :label-width="labelWidth" label="查稿系统">{{ curRow.applyReview == 1 ? '是' : '否' }}</info-row>
      <!-- <info-item :label-width="labelWidth" label="是否可逆">{{ curRow.reversible | bool }}</info-item> -->
      <!-- <info-item :label-width="labelWidth" label="是否考核">{{ (curRow.ruleId > 0) | bool }}</info-item> -->
      <!-- <info-item :label-width="labelWidth" label="是否评级">{{ curRow.rate | bool }}</info-item> -->
      <!-- <info-item :label-width="labelWidth" label="价格">{{
        curRow.ruleId > 0 ? '规则' + curRow.ruleId : '无'
      }}</info-item> -->
      <info-item :label-width="labelWidth" label="可驳回阶段">
        <a-tag v-for="one in curRow.rejectList" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="可考核阶段">
        <a-tag v-for="one in curRow.kpiList" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="预计耗时">{{
        curRow.predefinedTime != null || curRow.predefinedTime != undefined ? curRow.predefinedTime + ' 天' : ''
      }}</info-item>
      <g-title>规则</g-title>
      <a-collapse accordion>
        <a-collapse-panel v-for="rule in curRow.ruleList" :key="rule.id + ''" :header="rule.name">
          <edit-assess-form :isEdit="false" v-if="rule.ruleType == 'KPI'" :formData="rule" />
          <edit-level-form :isEdit="false" v-if="rule.ruleType == 'LEVEL'" :formList="rule.levelRules" />
          <edit-money-form :isEdit="false" v-if="rule.ruleType == 'MONEY'" :formData="rule" />
          <!--           <translate-coefficient
            :isEdit="false"
            :propsFormData="rule"
            :editMode="true"
            v-if="rule.ruleType == 'FACTOR'"
          >
          </translate-coefficient> -->
          <edit-factor-form :isEdit="false" :formData="rule" v-if="rule.ruleType == 'FACTOR'" />
          <edit-kpi-form :isEdit="false" :formData="rule" v-if="rule.ruleType == 'KPI_SCORE'" />
          <edit-coefficient-form :isEdit="false" :formData="rule" v-if="rule.ruleType == 'DIFFICULTLY'" />
          <edit-kpi-form v-if="rule.ruleType == 'NOT_LOCAL'" type="NOT_LOCAL" :isEdit="false" :formData="rule" />
          <!-- <edit-cof-form :isEdit="false" :formData="rule" v-if="rule.ruleType == 'COEFFICIENT'" /> -->
        </a-collapse-panel>
      </a-collapse>
    </div>
    <div slot="form">
      <g-title>基本信息</g-title>
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="名称" prop="templateName">
          <a-select
            placeholder="请选择阶段"
            show-search
            option-filter-prop="label"
            @change="stageChange"
            v-model="formData.stageId"
          >
            <a-select-option v-for="i in stageList" :key="i.id" :label="i.stageName">
              {{ i.stageName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input-number v-model="formData.sequenceNo" placeholder="请输入序号" class="w-full" />
        </a-form-model-item>
        <a-form-model-item label="人员" prop="userId">
          <!-- <a-auto-complete placeholder="请选择人员" v-model="formData.userId" :filter-option="filterOption">
            <template slot="dataSource">
              <a-select-option v-for="i in positionUserList" :key="i.id">{{ i.realname }}</a-select-option>
            </template>
          </a-auto-complete> -->
          <a-select
            v-if="formData.stageAdminUsers"
            v-model="formData.userId"
            show-search
            placeholder="请选择人员"
            option-filter-prop="label"
          >
            <a-select-option
              v-for="i in formData.stageAdminUsers"
              :value="i.userId"
              :key="i.userId"
              :label="i.userName"
            >
              <img style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="i.avatar" alt="" />
              <span>{{ i.userName }}</span>
            </a-select-option>
          </a-select>
          <a-select v-else v-model="formData.userId" show-search placeholder="请选择人员" option-filter-prop="label">
            <a-select-option v-for="i in adminUsers" :value="i.userId" :key="i.userId" :label="i.userName">
              <img style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="i.avatar" alt="" />
              <span>{{ i.userName }}</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--         <a-form-model-item label="是否可逆" prop="reversible">
          <a-switch checked-children="是" un-checked-children="否" default-checked v-model="formData.reversible" />
        </a-form-model-item> -->
        <a-form-model-item label="状态" prop="status">
          <a-switch checked-children="开" un-checked-children="关" default-checked v-model="status" />
        </a-form-model-item>
        <a-form-model-item label="翻译系统" prop="stageType">
          <a-switch checked-children="开" un-checked-children="关" default-checked v-model="applyTranslation" />
        </a-form-model-item>
        <a-form-model-item label="查稿系统" prop="stageType">
          <a-switch checked-children="开" un-checked-children="关" default-checked v-model="applyReview" />
        </a-form-model-item>
        <a-form-model-item label="可驳回阶段" prop="reversible">
          <!--           <a-tree-select
            v-model="rejectList"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreeStageList"
            show-search
            option-filter-prop="label"
            search-placeholder="选择可驳回阶段"
            placeholder="请选择可驳回阶段"
          /> -->
          <a-select
            v-model="rejectList"
            show-search
            placeholder="选择可驳回阶段"
            mode="multiple"
            option-filter-prop="label"
          >
            <a-select-option v-for="i in getTreeStageList" :value="i.value" :key="i.value" :label="i.title">
              <span>{{ i.title }}</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="可考核阶段" prop="reversible">
          <!--           <a-tree-select
            v-model="kpiList"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreeStageList"
            search-placeholder="选择可考核阶段"
            placeholder="请选择可考核阶段"
          /> -->
          <a-select
            v-model="kpiList"
            show-search
            placeholder="选择可考核阶段"
            mode="multiple"
            option-filter-prop="label"
          >
            <a-select-option v-for="i in getTreeStageList" :value="i.value" :key="i.value" :label="i.title">
              <span>{{ i.title }}</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="是否评级" prop="rate">
          <a-switch checked-children="是" un-checked-children="否" default-checked v-model="formData.rate" />
        </a-form-model-item>
        <a-form-model-item label="价格" prop="ruleId">
          <a-select v-model="formData.ruleId">
            <a-select-option :value="1">规则1</a-select-option>
            <a-select-option :value="2">规则2</a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item label="预计耗时" prop="predefinedTime">
          <a-input-number :min="0" v-model="formData.predefinedTime" />
        </a-form-model-item>
        <!-- <a-form-model-item label="是否考核" prop="rate" >
          <a-switch checked-children="是" un-checked-children="否" default-checked v-model="item.rate" />
        </a-form-model-item> -->
        <g-title>规则</g-title>
        <a-collapse accordion>
          <a-collapse-panel v-for="(rule, index) in formData.ruleList" :key="rule.id + ''" :header="rule.name">
            <a-icon
              class="text-xl tc-gray-600"
              slot="extra"
              type="close"
              @click.stop.prevent="
                e => {
                  closeRule(e, index)
                }
              "
            />
            <edit-assess-form :isEdit="true" v-if="rule.ruleType == 'KPI'" :formData="rule" />
            <edit-level-form :isEdit="true" v-if="rule.ruleType == 'LEVEL'" :formList="rule.levelRules" />
            <edit-money-form :isEdit="true" v-if="rule.ruleType == 'MONEY'" :formData="rule" />
            <!-- <translate-coefficient
              :isEdit="true"
              :propsFormData="rule"
              :editMode="true"
              v-if="rule.ruleType == 'FACTOR'"
            >
            </translate-coefficient> -->
            <edit-factor-form v-if="rule.ruleType == 'FACTOR'" :isEdit="true" :formData="rule" />
            <edit-coefficient-form :isEdit="true" v-if="rule.ruleType == 'DIFFICULTLY'" :formData="rule" />
            <edit-kpi-form :isEdit="true" :formData="rule" v-if="rule.ruleType == 'KPI_SCORE'" />
            <edit-kpi-form v-if="rule.ruleType == 'NOT_LOCAL'" type="NOT_LOCAL" :isEdit="true" :formData="rule" />
            <!-- <edit-cof-form :isEdit="true" :formData="rule" v-if="rule.ruleType == 'COEFFICIENT'" /> -->
          </a-collapse-panel>
        </a-collapse>
        <a-form-model-item label="添加规则" prop="userId" style="margin-top:40px;">
          <a-select @change="formSelectChange" placeholder="请选择规则">
            <a-select-option :disabled="isDis(i)" v-for="i in ruleList" :key="i.id" :value="i.id">{{
              i.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import { simpleDebounce } from '@/utils/util'
import TranslateLevel from '@/views/system/modules/rule/translate-level.vue'
import TranslateCheck from '@/views/system/modules/rule/translate-check.vue'
import TranslateCoefficient from '@/views/system/modules/rule/translate-coefficient.vue'
import TranslateDifficulty from '@/views/system/modules/rule/translate-difficulty'
import EditLevelForm from '@/components/edit/form-list/edit-level-form.vue'
import EditAssessForm from '@/components/edit/edit-assessRule-form.vue'
import EditMoneyForm from '@/components/edit/edit-money-form.vue'
import EditCoefficientForm from '@/components/edit/edit-coefficient-form.vue'
import EditFactorForm from '@/components/edit/edit-factor-form.vue'
import EditKpiForm from '@/components/edit/edit-kpi-form.vue'
import EditCofForm from '@/components/edit/edit-cof-form.vue'
import { postAction, getAction, putAction } from '@/api/manage'
export default {
  mixins: [DrawerMixins],
  props: {
    id: [Number, String],
    productionId: [Number, String],
    parent: {
      type: Object,
      default: () => ({})
    },
    /*     allRuleList: {
      type: Array,
      default: () => []
    }, */
    adminUsers: {
      type: Array,
      default: () => []
    }
  },
  components: {
    TranslateLevel,
    TranslateCheck,
    TranslateCoefficient,
    TranslateDifficulty,
    EditLevelForm,
    EditAssessForm,
    EditMoneyForm,
    EditCoefficientForm,
    EditFactorForm,
    EditKpiForm,
    EditCofForm
  },
  data() {
    return {
      allRuleList: [],
      activeKey: '',
      applyTranslation: false,
      applyReview: false,
      status: false,
      rejectList: [],
      positionId: '',
      kpiList: [],
      defaultUser: [],
      defaultAssUser: [],
      rules: {
        platformName: [{ required: true, message: '请输入平台名称', trigger: 'blur' }]
      },
      ruleList: [],
      url: {
        edit: '/production/edit_stage',
        add: '/production/add_stage'
      },
      labelWidth: '90',
      layout: {
        labelCol: { style: 'width: 90px;', span: 4 },
        wrapperCol: { span: 20 }
      }
    }
  },
  computed: {},
  created() {},
  methods: {
    save: simpleDebounce(async function() {
      this.saveSim()
    }, 500),
    saveSim() {
      const self = this
      this.setFormData()
      console.log('保存...')
      this.btnLoading = true
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          if (self.isEdit || self.isEditForm) {
            self.formData.platformId = self.curRow.platformId
            if (self.formData.applyTranslation) {
              self.formData.applyTranslation = 1
            } else {
              self.formData.applyTranslation = 0
            }
            if (self.formData.applyReview) {
              self.formData.applyReview = 1
            } else {
              self.formData.applyReview = 0
            }
            putAction(self.url.edit, self.formData).then(async res => {
              if (res.success) {
                if (
                  self.inspectSimpleValue == 'inspectSimpleBlack' ||
                  self.inspectSimpleValue == 'inspectSimpleFilter'
                ) {
                  const response = await postAction('/qualityInspection/blackWhiteSync', {})
                  if (response.data.code != 0) {
                    self.$message.error(response.data)
                  }
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.btnLoading = false
                self.closed()
              } else {
                self.btnLoading = false
                self.$message.error(res.message || res.msg)
              }
            })
          } else {
            console.log('新建URL', self.url.add)
            console.log('新建参数', self.formData)
            if (self.formData.applyTranslation) {
              self.formData.applyTranslation = 1
            } else {
              self.formData.applyTranslation = 0
            }
            if (self.formData.applyReview) {
              self.formData.applyReview = 1
            } else {
              self.formData.applyReview = 0
            }
            postAction(self.url.add, self.formData).then(async res => {
              if (res.success) {
                if (
                  self.inspectSimpleValue == 'inspectSimpleBlack' ||
                  self.inspectSimpleValue == 'inspectSimpleFilter'
                ) {
                  const response = await postAction('/qualityInspection/blackWhiteSync', {})
                  if (response.data.code != 0) {
                    self.$message.error(response.message)
                  }
                }
                self.$message.success(res.message || res.msg ? '保存成功' : '')
                self.isSaved = true
                self.btnLoading = false
                self.closed()
                self.closedModel()
              } else {
                self.btnLoading = false
                self.$message.error(res.message || res.msg)
              }
            })
          }
        } else {
          self.btnLoading = false
        }
      })
    },
    stageChange(v) {
      let stage = this.allRuleList.filter(one => one.stageName == this.stageList.find(s => s.id == v).stageName)
      if (stage.length > 0) {
        /*         this.formData.positionId = stage[0].positionId
        this.formData.sequenceNo = stage[0].sequenceNo */
        this.formData.applyTranslation = stage[0].applyTranslation
        this.applyTranslation = stage[0].applyTranslation ? true : false
        this.formData.applyReview = stage[0].applyReview
        this.applyReview = stage[0].applyReview ? true : false
      }
      this.ruleList = []
      this.allRuleList.forEach(item => {
        if (item.stageId == this.formData.stageId) {
          this.positionId = item.positionId
          this.ruleList = item.ruleList
        }
      })
    },
    async getPlatformStage() {
      const res = await getAction('platform/get_platform_stage', { id: this.parent.platformId })
      this.ruleList = []
      this.allRuleList = res.data
      res.data.forEach(item => {
        if (item.stageId == this.curRow.stageId) {
          this.positionId = item.positionId
          this.ruleList = item.ruleList
        }
      })
    },
    isDis(i) {
      if (this.formData.ruleList) {
        return this.formData.ruleList?.map(item => item.name).indexOf(i.name) == -1 ? false : true
      } else {
        return false
      }
    },
    closeRule(event, index) {
      this.formData.ruleList.splice(index, 1)
    },
    formSelectChange(id) {
      const item = { ...this.ruleList[this.ruleList?.map(item => item.id).indexOf(id)] }
      let ruleList = this.formData.ruleList || []
      let object = JSON.parse(JSON.stringify(item))
      ruleList.push(object)
      this.activeKey = id + ''
      this.formData = Object.assign({}, this.formData, { ruleList })
    },

    initForm() {
      this.formData = {
        id: null,
        productionId: null,
        positionId: '',
        positionName: '',
        predefinedTime: 0,
        rate: true,
        reversible: true,
        ruleId: null,
        sequenceNo: null,
        stageId: null,
        stageName: '',
        userId: '',
        userName: '',
        rejectList: [],
        kpiList: [],
        applyTranslation: 0,
        applyReview: 0
      }
      this.applyTranslation = false
      this.applyReview = false
    },
    edit() {
      this.getPlatformStage()
      this.getStageList()
      this.isEdit = true
      this.isSaved = false
      console.log('编辑...')
      this.formData = this.curRow
      if (this.formData.stageId) {
        this.stageChange(this.formData.stageId)
      }
      if (this.curRow.applyTranslation) {
        this.applyTranslation = true
      } else {
        this.applyTranslation = false
      }
      if (this.curRow.applyReview) {
        this.applyReview = true
      } else {
        this.applyReview = false
      }
      if (this.curRow.status) {
        this.status = true
      } else {
        this.status = false
      }
      this.rejectList = []
      this.kpiList = []
      this.rejectList = this.curRow.rejectList?.map(item => item.stageId)
      this.kpiList = this.curRow.kpiList?.map(item => item.stageId)
      console.log({ 'this.formData': this.formData })
      this.oldFormData = JSON.parse(JSON.stringify(this.formData))
      this.startEdit()
    },
    async getRuleList() {
      try {
        const res = await getAction('/rule/list', { domain: 'GLOBAL', departId: this.parent.departmentId })
        if (res.code == 200) {
          this.ruleList = []
          res.data.forEach(item => {
            if (item.ruleType == 'LEVEL' && item.members?.find(i => i.positionId == this.curRow.positionId)) {
              this.ruleList.push(item)
            } else if (item.ruleType != 'LEVEL') {
              item.stages.forEach(i => {
                if (i.stageId == this.curRow.stageId) {
                  this.ruleList.push(item)
                }
              })
            }
          })
          console.log({ 'this.ruleList': this.ruleList })
        } else {
          this.$message.error(res.msg || res.message)
        }
      } catch (err) {
        console.log(err)
      }
    },
    show() {
      console.log('显示...')
      // 通过获取平台阶段，得到平台阶段上的postitionId
      this.getPlatformStage()
      this.getPosition()
      this.getStageList()
      if (this.curRow.ruleList) {
        this.activeKey = this.curRow.ruleList[0].id + ''
      }
      this.rejectList = []
      this.kpiList = []
      this.rejectList = this.curRow.rejectList?.map(item => item.stageId)
      this.kpiList = this.curRow.kpiList?.map(item => item.stageId)
      if (this.curRow.applyTranslation) {
        this.applyTranslation = true
      } else {
        this.applyTranslation = false
      }
      if (this.curRow.applyReview) {
        this.applyReview = true
      } else {
        this.applyReview = false
      }
      if (this.curRow.status) {
        this.status = true
      } else {
        this.status = false
      }
    },

    setFormData() {
      this.formData.platformId = this.platformId
      this.formData.departId = this.parent.departmentId
      this.formData.productionId = this.parent.productionId
      if (this.formData.ruleList) {
        this.formData.ruleList?.forEach(item => {
          let newData = item ? JSON.parse(JSON.stringify(item)) : {}
          let oldData = this.ruleList[
            this.ruleList
              ?.map(i => {
                return i.name
              })
              .indexOf(item.name)
          ]
            ? JSON.parse(
                JSON.stringify(
                  this.ruleList[
                    this.ruleList
                      ?.map(i => {
                        return i.name
                      })
                      .indexOf(item.name)
                  ]
                )
              )
            : {}
          delete newData.id
          delete oldData.id
          delete newData.domain
          delete oldData.domain
          delete oldData.relationId
          delete newData.relationId
          delete oldData.hasUpdated
          delete newData.hasUpdated
          delete oldData.createTime
          delete newData.createTime
          delete oldData.createBy
          delete newData.createBy
          delete oldData.updateTime
          delete newData.updateTime
          delete oldData.updateBy
          delete newData.updateBy
          delete oldData.sequenceNo
          delete newData.sequenceNo
          oldData.kpiDeliveries?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.gmtCreate
          })
          newData.kpiDeliveries?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.gmtCreate
          })
          oldData.kpiErrors?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.gmtCreate
          })
          newData.kpiErrors?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.gmtCreate
          })
          oldData.levelRules?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.averageId
            delete oldItem.quotaId
            delete oldItem.durationId
            delete oldItem.scoreId
            delete oldItem.quota?.id
            delete oldItem.duration?.id
            delete oldItem.average?.id
            delete oldItem.score?.id
            delete oldItem.quota?.unitLabel
            delete oldItem.duration?.unitLabel
            delete oldItem.average?.unitLabel
            delete oldItem.score?.gmtCreate
            delete oldItem.quota?.gmtCreate
            delete oldItem.duration?.gmtCreate
            delete oldItem.average?.gmtCreate
            delete oldItem.gmtCreate
          })
          newData.levelRules?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.averageId
            delete newItem.quotaId
            delete newItem.durationId
            delete newItem.scoreId
            delete newItem.quota?.id
            delete newItem.duration?.id
            delete newItem.average?.id
            delete newItem.quota?.unitLabel
            delete newItem.duration?.unitLabel
            delete newItem.average?.unitLabel
            delete newItem.score?.id
            delete newItem.quota?.gmtCreate
            delete newItem.duration?.gmtCreate
            delete newItem.average?.gmtCreate
            delete newItem.score?.gmtCreate
            delete newItem.gmtCreate
          })
          oldData.difficulties?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.factors
            delete oldItem.gmtCreate
            oldItem.categories?.forEach(o => {
              delete o.categoryId
              delete o.id
              delete o.rangeId
              delete o.typeId
              delete o.gmtCreate
              o.options?.forEach(oi => {
                delete oi.range?.id
                delete oi.categoryId
                delete oi.id
                delete oi.rangeId
                delete oi.gmtCreate
                delete oi.range?.gmtCreate
              })
              o.range?.forEach(oi => {
                delete oi.id
                delete ol.gmtCreate
              })
            })
          })
          newData.difficulties?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.factors
            delete newItem.gmtCreate
            newItem.categories?.forEach(o => {
              delete o.categoryId
              delete o.id
              delete o.rangeId
              delete o.typeId
              delete o.gmtCreate
              o.options?.forEach(oi => {
                delete oi.range?.id
                delete oi.range?.id
                delete oi.categoryId
                delete oi.id
                delete oi.rangeId
                delete oi.gmtCreate
                delete oi.range?.gmtCreate
              })
              o.range?.forEach(oi => {
                delete oi.id
                delete ol.gmtCreate
              })
            })
          })
          oldData.kpiModifies?.forEach(oldItem => {
            delete oldItem.id
            delete oldItem.ruleId
            delete oldItem.rangeId
            delete oldItem.range?.id
            delete oldItem.range?.unitLabel
            delete oldItem.gmtCreate
            delete oldItem.range?.gmtCreate
          })
          newData.kpiModifies?.forEach(newItem => {
            delete newItem.id
            delete newItem.ruleId
            delete newItem.rangeId
            delete newItem.range?.id
            delete newItem.range?.unitLabel
            delete newItem.gmtCreate
            delete newItem.range?.gmtCreate
          })
          delete oldData.departments
          delete newData.departments
          delete oldData.positions
          delete newData.positions
          delete oldData.stages
          delete newData.stages
          console.log(newData, oldData, item.name, 213)
          oldData = JSON.stringify(oldData)
          newData = JSON.stringify(newData)
          newData != oldData && (item.hasUpdated = true)
          newData == oldData && (item.hasUpdated = false)
        })
      } else {
        this.formData.ruleList = []
      }
      this.formData.rejectList = []
      this.formData.kpiList = []
      this.rejectList?.forEach(item => {
        this.formData.rejectList.push({
          stageId: item
        })
      })
      this.kpiList?.forEach(item => {
        this.formData.kpiList.push({
          stageId: item
        })
      })
      if (this.applyTranslation) {
        this.formData.applyTranslation = 1
      } else {
        this.formData.applyTranslation = 0
      }
      if (this.applyReview) {
        this.formData.applyReview = 1
      } else {
        this.formData.applyReview = 0
      }
      if (this.status) {
        this.formData.status = 0
      } else {
        this.formData.status = 1
      }
      this.formData.reversible = Boolean(this.formData.rejectList?.length)
      console.log(this.formData, 'formData')
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
    }
  }
}
</script>

<style lang="less" scoped>
.border-bottom {
  border-bottom: none;
}
/deep/ .ant-collapse {
  background-color: #fff !important;
  border: none !important;
  border-radius: 0 !important;
  width: 98.5%;
}
/deep/ .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-right: 0.6rem !important;
  font-size: 1rem !important;
}
/deep/ .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0;
}
/deep/ .ant-input-number-input {
  padding-right: 5px !important;
}
.ant-select-selection__rendered {
  img {
    display: none;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        title: _vm.setTitle,
        show: _vm.open,
        isChapter: false,
        isForm: _vm.isForm,
        btnLoading: _vm.btnLoading,
        permission: _vm.parent.isTongChou
          ? "production:stage:button:edit"
          : "production:stage:button:edit:isTongChou",
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "" }, slot: "default" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "名称" } },
            [_vm._v(_vm._s(_vm.curRow.stageName))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sequenceNo))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "人员" } },
            [
              _c("div", [
                _vm.curRow.user
                  ? _c("img", {
                      staticStyle: {
                        width: "30px",
                        height: "30px",
                        "border-radius": "50%",
                        "margin-right": "10px",
                      },
                      attrs: { src: _vm.curRow.user.avatar, alt: "" },
                    })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.curRow ? _vm.curRow.userName : "") +
                    "\n      "
                ),
              ]),
            ]
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "状态" } },
            [_vm._v(_vm._s(_vm.curRow.status ? "开" : "关"))]
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "翻译系统" } },
            [_vm._v(_vm._s(_vm.curRow.applyTranslation == 1 ? "是" : "否"))]
          ),
          _c(
            "info-row",
            { attrs: { "label-width": _vm.labelWidth, label: "查稿系统" } },
            [_vm._v(_vm._s(_vm.curRow.applyReview == 1 ? "是" : "否"))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "可驳回阶段" } },
            _vm._l(_vm.curRow.rejectList, function (one) {
              return _c("a-tag", { key: one.stageId }, [
                _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
              ])
            }),
            1
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "可考核阶段" } },
            _vm._l(_vm.curRow.kpiList, function (one) {
              return _c("a-tag", { key: one.stageId }, [
                _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
              ])
            }),
            1
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "预计耗时" } },
            [
              _vm._v(
                _vm._s(
                  _vm.curRow.predefinedTime != null ||
                    _vm.curRow.predefinedTime != undefined
                    ? _vm.curRow.predefinedTime + " 天"
                    : ""
                )
              ),
            ]
          ),
          _c("g-title", [_vm._v("规则")]),
          _c(
            "a-collapse",
            { attrs: { accordion: "" } },
            _vm._l(_vm.curRow.ruleList, function (rule) {
              return _c(
                "a-collapse-panel",
                { key: rule.id + "", attrs: { header: rule.name } },
                [
                  rule.ruleType == "KPI"
                    ? _c("edit-assess-form", {
                        attrs: { isEdit: false, formData: rule },
                      })
                    : _vm._e(),
                  rule.ruleType == "LEVEL"
                    ? _c("edit-level-form", {
                        attrs: { isEdit: false, formList: rule.levelRules },
                      })
                    : _vm._e(),
                  rule.ruleType == "MONEY"
                    ? _c("edit-money-form", {
                        attrs: { isEdit: false, formData: rule },
                      })
                    : _vm._e(),
                  rule.ruleType == "FACTOR"
                    ? _c("edit-factor-form", {
                        attrs: { isEdit: false, formData: rule },
                      })
                    : _vm._e(),
                  rule.ruleType == "KPI_SCORE"
                    ? _c("edit-kpi-form", {
                        attrs: { isEdit: false, formData: rule },
                      })
                    : _vm._e(),
                  rule.ruleType == "DIFFICULTLY"
                    ? _c("edit-coefficient-form", {
                        attrs: { isEdit: false, formData: rule },
                      })
                    : _vm._e(),
                  rule.ruleType == "NOT_LOCAL"
                    ? _c("edit-kpi-form", {
                        attrs: {
                          type: "NOT_LOCAL",
                          isEdit: false,
                          formData: rule,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "form" }, slot: "form" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "templateName" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择阶段",
                        "show-search": "",
                        "option-filter-prop": "label",
                      },
                      on: { change: _vm.stageChange },
                      model: {
                        value: _vm.formData.stageId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "stageId", $$v)
                        },
                        expression: "formData.stageId",
                      },
                    },
                    _vm._l(_vm.stageList, function (i) {
                      return _c(
                        "a-select-option",
                        { key: i.id, attrs: { label: i.stageName } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(i.stageName) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input-number", {
                    staticClass: "w-full",
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sequenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sequenceNo", $$v)
                      },
                      expression: "formData.sequenceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "人员", prop: "userId" } },
                [
                  _vm.formData.stageAdminUsers
                    ? _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择人员",
                            "option-filter-prop": "label",
                          },
                          model: {
                            value: _vm.formData.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "userId", $$v)
                            },
                            expression: "formData.userId",
                          },
                        },
                        _vm._l(_vm.formData.stageAdminUsers, function (i) {
                          return _c(
                            "a-select-option",
                            {
                              key: i.userId,
                              attrs: { value: i.userId, label: i.userName },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "30px",
                                  height: "30px",
                                  "border-radius": "50%",
                                  "margin-right": "10px",
                                },
                                attrs: { src: i.avatar, alt: "" },
                              }),
                              _c("span", [_vm._v(_vm._s(i.userName))]),
                            ]
                          )
                        }),
                        1
                      )
                    : _c(
                        "a-select",
                        {
                          attrs: {
                            "show-search": "",
                            placeholder: "请选择人员",
                            "option-filter-prop": "label",
                          },
                          model: {
                            value: _vm.formData.userId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "userId", $$v)
                            },
                            expression: "formData.userId",
                          },
                        },
                        _vm._l(_vm.adminUsers, function (i) {
                          return _c(
                            "a-select-option",
                            {
                              key: i.userId,
                              attrs: { value: i.userId, label: i.userName },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "30px",
                                  height: "30px",
                                  "border-radius": "50%",
                                  "margin-right": "10px",
                                },
                                attrs: { src: i.avatar, alt: "" },
                              }),
                              _c("span", [_vm._v(_vm._s(i.userName))]),
                            ]
                          )
                        }),
                        1
                      ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": "开",
                      "un-checked-children": "关",
                      "default-checked": "",
                    },
                    model: {
                      value: _vm.status,
                      callback: function ($$v) {
                        _vm.status = $$v
                      },
                      expression: "status",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "翻译系统", prop: "stageType" } },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": "开",
                      "un-checked-children": "关",
                      "default-checked": "",
                    },
                    model: {
                      value: _vm.applyTranslation,
                      callback: function ($$v) {
                        _vm.applyTranslation = $$v
                      },
                      expression: "applyTranslation",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "查稿系统", prop: "stageType" } },
                [
                  _c("a-switch", {
                    attrs: {
                      "checked-children": "开",
                      "un-checked-children": "关",
                      "default-checked": "",
                    },
                    model: {
                      value: _vm.applyReview,
                      callback: function ($$v) {
                        _vm.applyReview = $$v
                      },
                      expression: "applyReview",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "可驳回阶段", prop: "reversible" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        placeholder: "选择可驳回阶段",
                        mode: "multiple",
                        "option-filter-prop": "label",
                      },
                      model: {
                        value: _vm.rejectList,
                        callback: function ($$v) {
                          _vm.rejectList = $$v
                        },
                        expression: "rejectList",
                      },
                    },
                    _vm._l(_vm.getTreeStageList, function (i) {
                      return _c(
                        "a-select-option",
                        {
                          key: i.value,
                          attrs: { value: i.value, label: i.title },
                        },
                        [_c("span", [_vm._v(_vm._s(i.title))])]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "可考核阶段", prop: "reversible" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        placeholder: "选择可考核阶段",
                        mode: "multiple",
                        "option-filter-prop": "label",
                      },
                      model: {
                        value: _vm.kpiList,
                        callback: function ($$v) {
                          _vm.kpiList = $$v
                        },
                        expression: "kpiList",
                      },
                    },
                    _vm._l(_vm.getTreeStageList, function (i) {
                      return _c(
                        "a-select-option",
                        {
                          key: i.value,
                          attrs: { value: i.value, label: i.title },
                        },
                        [_c("span", [_vm._v(_vm._s(i.title))])]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "预计耗时", prop: "predefinedTime" } },
                [
                  _c("a-input-number", {
                    attrs: { min: 0 },
                    model: {
                      value: _vm.formData.predefinedTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "predefinedTime", $$v)
                      },
                      expression: "formData.predefinedTime",
                    },
                  }),
                ],
                1
              ),
              _c("g-title", [_vm._v("规则")]),
              _c(
                "a-collapse",
                { attrs: { accordion: "" } },
                _vm._l(_vm.formData.ruleList, function (rule, index) {
                  return _c(
                    "a-collapse-panel",
                    { key: rule.id + "", attrs: { header: rule.name } },
                    [
                      _c("a-icon", {
                        staticClass: "text-xl tc-gray-600",
                        attrs: { slot: "extra", type: "close" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return ((e) => {
                              _vm.closeRule(e, index)
                            }).apply(null, arguments)
                          },
                        },
                        slot: "extra",
                      }),
                      rule.ruleType == "KPI"
                        ? _c("edit-assess-form", {
                            attrs: { isEdit: true, formData: rule },
                          })
                        : _vm._e(),
                      rule.ruleType == "LEVEL"
                        ? _c("edit-level-form", {
                            attrs: { isEdit: true, formList: rule.levelRules },
                          })
                        : _vm._e(),
                      rule.ruleType == "MONEY"
                        ? _c("edit-money-form", {
                            attrs: { isEdit: true, formData: rule },
                          })
                        : _vm._e(),
                      rule.ruleType == "FACTOR"
                        ? _c("edit-factor-form", {
                            attrs: { isEdit: true, formData: rule },
                          })
                        : _vm._e(),
                      rule.ruleType == "DIFFICULTLY"
                        ? _c("edit-coefficient-form", {
                            attrs: { isEdit: true, formData: rule },
                          })
                        : _vm._e(),
                      rule.ruleType == "KPI_SCORE"
                        ? _c("edit-kpi-form", {
                            attrs: { isEdit: true, formData: rule },
                          })
                        : _vm._e(),
                      rule.ruleType == "NOT_LOCAL"
                        ? _c("edit-kpi-form", {
                            attrs: {
                              type: "NOT_LOCAL",
                              isEdit: true,
                              formData: rule,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticStyle: { "margin-top": "40px" },
                  attrs: { label: "添加规则", prop: "userId" },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择规则" },
                      on: { change: _vm.formSelectChange },
                    },
                    _vm._l(_vm.ruleList, function (i) {
                      return _c(
                        "a-select-option",
                        {
                          key: i.id,
                          attrs: { disabled: _vm.isDis(i), value: i.id },
                        },
                        [_vm._v(_vm._s(i.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }